<template>
  <div class="autoeval-process">
    <div v-if="showTutoriel" class="hovering-infos-backdrop">
      <b-card class="hovering-card p-1">
        <h4 class="mb-1">Informations utiles</h4>

        <div>
          <b class="mb-1"> Bienvenue sur le formulaire d'auto-évaluation!</b>

          <p>
            Nous sommes ravis de recueillir vos précieuses informations à
            travers notre questionnaire structuré. Pour garantir une expérience
            fluide, le formulaire est divisé en chapitres, chaque chapitre
            comportant des rubriques spécifiques.
          </p>

          <b class="mb-1"> Structure du formulaire : </b>

          <ul>
            <li>
              <b>Chapitres : </b>Ces grandes catégories définissent les grands
              thèmes couverts par le questionnaire.
            </li>
            <li>
              <b>Rubriques : </b>Chaque chapitre est subdivisé en rubriques pour
              une organisation claire. Chaque rubrique aborde des aspects
              spécifiques liés au thème du chapitre.
            </li>
            <li>
              <b>Critères : </b>Au sein de chaque rubrique, vous trouverez des
              critères pertinentes. Lisez attentivement chaque critère pour
              comprendre son contexte.
            </li>
          </ul>

          <b class="mb-1">Approches de notation :</b>

          <ul>
            <li>
              <b>Binaire : </b>Certains critères nécessitent une notation simple
              de type oui ou non. Sélectionnez l'option qui correspond le mieux
              à votre situation.
            </li>
            <li>
              <b>Echelle : </b> Le système de notation est dit « à échelle »
              lorsqu’il est affecté au maximum quatre (04) modalités, à savoir «
              0 », « 1 », « 2 » ou « 3 », choisissez le chiffre qui reflète le
              mieux votre opinion ou votre expérience.
            </li>
            <!-- <li>
              <b>Liste de propositions : </b>Certaines questions présentent une
              liste d'options parmi lesquelles vous pouvez choisir. Sélectionnez
              la réponse qui correspond le mieux à votre situation.
            </li> -->
          </ul>

          <b class="mb-1">Notez : </b>Chaque critère permet une seule réponse,
          veuillez choisir celle qui vous semble la plus appropriée.
          <br />
          <p>
            Nous vous remercions sincèrement de prendre le temps de remplir ce
            formulaire. Vos réponses nous sont d'une grande valeur et
            contribuent à améliorer nos services. Si vous avez des questions,
            n'hésitez pas à nous contacter. Merci de votre participation active!
          </p>
          <br />
        </div>
        <div class="d-flex justify-content-end">
          <b-button
            variant="primary"
            size="sm"
            style="margin-right: 8px"
            @click="() => (this.showTutoriel = false)"
            >{{ $t('home.welcomeCardOk') }}</b-button
          >
          <b-button
            variant="outline-primary"
            size="sm"
            @click="handleTutorielMessage()"
            >{{ $t('home.welcomeCardOkIndefinitely') }}</b-button
          >
        </div>
      </b-card>
    </div>
    <b-modal
      id="modal-confirmation-process"
      ref="modal-confirmation-process"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      size="sm"
      title="Êtes-vous sûr(e) de vouloir valider. Aucune modification ne pourra être
apportée!"
      hide-footer
    >
      <div class="d-flex justify-content-center align-items-center">
        <b-button
          size="sm"
          class="mr-1"
          variant="gradient-danger"
          @click="closeConfirmationModal()"
          >Annulé</b-button
        >
        <b-button size="sm" variant="gradient-primary" @click="launchProcess()"
          >Oui, valider</b-button
        >
      </div>
    </b-modal>
    <b-modal
      id="modal-end-evaluation"
      ref="modal-end-evaluation"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      class="endmodal"
      centered
      size="lg"
      title="Résultat de l'auto-évaluation"
      hide-footer
    >
      <div
        v-if="isSendingEvaluation || isPendingUpdate"
        class="d-flex mb-3 mt-2 justify-content-center"
      >
        <b-spinner variant="primary" />
      </div>
      <div v-if="evaluationResult && !isPendingUpdate">
        <span
          ><b>Evaluation</b>:
          <span class="text-primary d-inline-block ml-1">{{
            evaluationResult.title
          }}</span></span
        >
        <!-- <br /> -->
        <hr />
        <b class="mb-1 d-inline-block">Rapport d'éligibilité par classement</b>
        <br />
        <!-- ❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎ -->
        <!-- <div>
          <pre>{{ evaluationResult }}</pre>
        </div> -->
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              v-b-toggle="'collapse-1'"
              size="sm"
              style="width: 100px; margin: 4px 0"
              :variant="
                evaluationResult.results.is_eligible
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              >{{ evaluationResult.results.class }} étoile</b-button
            >
            <b v-if="evaluationResult.results.is_eligible" class="text-success"
              >ELIGIBLE</b
            >
            <b v-if="!evaluationResult.results.is_eligible" class="text-danger"
              >NON ELIGIBLE</b
            >
          </div>
          <ResultOutputAutoEval
            :results="evaluationResult.results"
            :totals="evaluationResult.results.totals"
          />
        </div>
        <!-- <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              size="sm"
              v-b-toggle="'collapse-2'"
              style="width: 100px; margin: 4px 0"
              :variant="
                evaluationResult.results['2 Etoiles'].is_eligible
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              >2 étoiles</b-button
            >
            <b
              v-if="evaluationResult.results['2 Etoiles'].is_eligible"
              class="text-success"
              >ELIGIBLE</b
            >
            <b
              v-if="!evaluationResult.results['2 Etoiles'].is_eligible"
              class="text-danger"
              >NON ELIGIBLE</b
            >
          </div>
          <b-collapse id="collapse-2" class="mt-1">
            <ResultsOutput
              :results="evaluationResult.results"
              :classementNumber="String(2)"
            />
          </b-collapse>
        </div>
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              size="sm"
              v-b-toggle="'collapse-3'"
              style="width: 100px; margin: 4px 0"
              :variant="
                evaluationResult.results['3 Etoiles'].is_eligible
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              >3 étoiles</b-button
            >
            <b
              v-if="evaluationResult.results['3 Etoiles'].is_eligible"
              class="text-success"
              >ELIGIBLE</b
            >
            <b
              v-if="!evaluationResult.results['3 Etoiles'].is_eligible"
              class="text-danger"
              >NON ELIGIBLE</b
            >
          </div>
          <b-collapse id="collapse-3" class="mt-1">
            <ResultsOutput
              :results="evaluationResult.results"
              :classementNumber="String(3)"
            />
          </b-collapse>
        </div>
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              size="sm"
              v-b-toggle="'collapse-4'"
              style="width: 100px; margin: 4px 0"
              :variant="
                evaluationResult.results['4 Etoiles'].is_eligible
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              >4 étoiles</b-button
            >
            <b
              v-if="evaluationResult.results['4 Etoiles'].is_eligible"
              class="text-success"
              >ELIGIBLE</b
            >
            <b
              v-if="!evaluationResult.results['4 Etoiles'].is_eligible"
              class="text-danger"
              >NON ELIGIBLE</b
            >
          </div>
          <b-collapse id="collapse-4" class="mt-1">
            <ResultsOutput
              :results="evaluationResult.results"
              :classementNumber="String(4)"
            />
          </b-collapse>
        </div>
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              size="sm"
              v-b-toggle="'collapse-5'"
              style="width: 100px; margin: 4px 0"
              :variant="
                evaluationResult.results['5 Etoiles'].is_eligible
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              >5 étoiles</b-button
            >
            <b
              v-if="evaluationResult.results['5 Etoiles'].is_eligible"
              class="text-success"
              >ELIGIBLE</b
            >
            <b
              v-if="!evaluationResult.results['5 Etoiles'].is_eligible"
              class="text-danger"
              >NON ELIGIBLE</b
            >
          </div>
          <b-collapse id="collapse-5" class="mt-1">
            <ResultsOutput
              :results="evaluationResult.results"
              :classementNumber="String(5)"
            />
          </b-collapse>
        </div> -->
        <!-- ❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎❎ -->
        <!-- ⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔ -->
        <!-- <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              size="sm"
              v-b-toggle="'collapse-1'"
              style="width: 100px; margin: 4px 0"
              :variant="
                starEligibility >= 1 ? 'gradient-success' : 'gradient-danger'
              "
              >1 étoile</b-button
            >
            <b v-if="starEligibility >= 1" class="text-success">ELIGIBLE</b>
            <b v-else="!starEligibility < 1" class="text-danger"
              >NON ELIGIBLE</b
            >
          </div>
          <b-collapse id="collapse-1" class="mt-1">
            <ResultsOutput
              :results="evaluationResult.results"
              :classementNumber="String(1)"
            />
          </b-collapse>
        </div>
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              size="sm"
              v-b-toggle="'collapse-2'"
              style="width: 100px; margin: 4px 0"
              :variant="
                starEligibility >= 2 ? 'gradient-success' : 'gradient-danger'
              "
              >2 étoiles</b-button
            >
            <b v-if="starEligibility >= 2" class="text-success">ELIGIBLE</b>
            <b v-else class="text-danger">NON ELIGIBLE</b>
          </div>
          <b-collapse id="collapse-2" class="mt-1">
            <ResultsOutput
              :results="evaluationResult.results"
              :classementNumber="String(2)"
            />
          </b-collapse>
        </div>
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              size="sm"
              v-b-toggle="'collapse-3'"
              style="width: 100px; margin: 4px 0"
              :variant="
                starEligibility >= 3 ? 'gradient-success' : 'gradient-danger'
              "
              >3 étoiles</b-button
            >
            <b v-if="starEligibility >= 3" class="text-success">ELIGIBLE</b>
            <b v-else class="text-danger">NON ELIGIBLE</b>
          </div>
          <b-collapse id="collapse-3" class="mt-1">
            <ResultsOutput
              :results="evaluationResult.results"
              :classementNumber="String(3)"
            />
          </b-collapse>
        </div>
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              size="sm"
              v-b-toggle="'collapse-4'"
              style="width: 100px; margin: 4px 0"
              :variant="
                starEligibility >= 4 ? 'gradient-success' : 'gradient-danger'
              "
              >4 étoiles</b-button
            >
            <b v-if="starEligibility >= 4" class="text-success">ELIGIBLE</b>
            <b v-else class="text-danger">NON ELIGIBLE</b>
          </div>
          <b-collapse id="collapse-4" class="mt-1">
            <ResultsOutput
              :results="evaluationResult.results"
              :classementNumber="String(4)"
            />
          </b-collapse>
        </div>
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              size="sm"
              v-b-toggle="'collapse-5'"
              style="width: 100px; margin: 4px 0"
              :variant="
                starEligibility >= 5 ? 'gradient-success' : 'gradient-danger'
              "
              >5 étoiles</b-button
            >
            <b v-if="starEligibility >= 5" class="text-success">ELIGIBLE</b>
            <b v-else class="text-danger">NON ELIGIBLE</b>
          </div>
          <b-collapse id="collapse-5" class="mt-1">
            <ResultsOutput
              :results="evaluationResult.results"
              :classementNumber="String(5)"
            />
          </b-collapse>
        </div> -->
        <!-- ⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔⛔ -->
        <hr />
      </div>
      <b-button
        block
        variant="primary"
        class="mb-1"
        :disabled="isSendingEvaluation || isPendingUpdate"
        @click="closeModal()"
        >Terminer</b-button
      >
    </b-modal>

    <b-card no-body style="padding: 3em 0">
      <div
        v-if="autoEvaluation"
        class="d-flex flex-column align-items-center justify-content-center text-center"
      >
        <h2>
          Auto-évaluation -
          <span class="text-primary">{{ autoEvaluation.title }}</span>
        </h2>
        <div
          v-if="autoEvaluation && autoEvaluation.data"
          class="progress-wrapper"
        >
          <b-card-text class="mb-0">
            Pourcentage de progression -
            <b>{{ progressCalculation.toFixed(0) + '%' }}</b>
            <!-- <b>{{
                autoEvaluation.progression.progressPercentage.toFixed(0) + '%'
              }}</b> -->
          </b-card-text>
          <b-progress v-model="progressCalculation" max="100" />
        </div>
      </div>
      <b-tabs v-model="tabIndex" style="margin-top: 2.5em" fill>
        <!-- <p>Current Tab: {{ tabIndex }}</p> -->
        <!-- <app-collapse accordion>
          <app-collapse-item :isVisible="true" title="Accordion Item 1">
            Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant.
            Tootsie roll sweet candy bear claw chupa chups lollipop toffee.
            Macaroon donut liquorice powder candy carrot cake macaroon
            fruitcake. Cookie toffee lollipop cotton candy ice cream dragée
            soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart.
            Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé
            icing caramels. Chocolate cake icing ice cream macaroon pie
            cheesecake liquorice apple pie.
          </app-collapse-item>
          <app-collapse-item title="Accordion Item 2">
            GLOU GLOU
          </app-collapse-item>
        </app-collapse> -->

        <!-- <div class="px-3">
          <b-alert show dismissible>
            Dismissible Alert! Click the close button over there <b>&rArr;</b>
          </b-alert>
        </div> -->

        <div class="text-center">
          <div class="mt-3 d-flex justify-content-center" style="gap: 1em">
            <!-- @click="tabIndex++" -->
            <b-button
              v-if="tabIndex === 0 && autoEvaluation.data.chapters.length > 1"
              variant="gradient-primary"
              size="sm"
              :disabled="isSendingEvaluation || isPendingUpdate"
              @click="tabIndex++"
            >
              <span class="text-nowrap"
                >Chapitre suivant

                <i
                  class="las la-arrow-right la-lg d-inline-block"
                  style="margin-left: 5px"
                />
              </span>
            </b-button>
            <b-button
              v-if="tabIndex > 0"
              variant="gradient-primary"
              size="sm"
              :disabled="isSendingEvaluation || isPendingUpdate"
              @click="tabIndex--"
            >
              <span class="text-nowrap">
                <i
                  class="las la-arrow-left la-lg d-inline-block"
                  style="margin-right: 5px"
                />
                Chapitre précédent
              </span>
            </b-button>
            <b-button
              v-if="tabIndex === autoEvaluation.data.chapters.length - 1"
              variant="gradient-primary"
              size="sm"
              :disabled="isSendingEvaluation || isPendingUpdate"
              @click="openConfirmationModal()"
            >
              <span class="text-nowrap"
                >Terminer

                <i
                  class="las la-check la-lg d-inline-block"
                  style="margin-left: 5px"
                />
              </span>
            </b-button>
            <b-button
              v-if="
                tabIndex != 0 &&
                tabIndex !== autoEvaluation.data.chapters.length - 1
              "
              variant="gradient-primary"
              size="sm"
              :disabled="isSendingEvaluation || isPendingUpdate"
              @click="tabIndex++"
            >
              <span class="text-nowrap"
                >Chapitre suivant

                <i
                  class="las la-arrow-right la-lg d-inline-block"
                  style="margin-left: 5px"
                />
              </span>
            </b-button>
          </div>
        </div>
        <div class="px-3">
          <div class="d-flex pb-1">
            <b-badge pill :variant="isPendingUpdate ? 'primary' : 'secondary'">
              {{ !isPendingUpdate ? 'A jour' : 'Enregistrement' }}
              <small
                v-if="isPendingUpdate"
                class="d-inline-block"
                style="margin-left: 5px"
              >
                <b-spinner small type="grow" variant="success" />
              </small>
            </b-badge>
          </div>
          <b-tab
            v-for="(item, chapterIndex) in autoEvaluation.data.chapters"
            v-if="autoEvaluation && autoEvaluation.data"
            :key="chapterIndex"
            :title-link-class="linkClass(chapterIndex)"
            :title="item.n + '. ' + item.label"
          >
            <app-collapse accordion type="border">
              <custom-app-collapse-item
                v-for="(secItem, secIndex) in item.sections"
                :key="secIndex"
                :questions_count="secItem.total_points"
                :questions_total="secItem.questions.length"
                :title="secItem.label"
                :is-visible="secItem.isOpen"
              >
                <b-table
                  ref="refAutoEvaTable"
                  :items="secItem.questions"
                  responsive
                  :fields="tableColumnsQuestion"
                  show-empty
                  empty-text="Aucune donnée"
                  :busy="isLoading"
                  hover
                >
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle" />
                    </div>
                  </template>
                  <template #cell(question)="data">
                    <div>
                      <span>{{ data.item.question }}</span>
                      <!-- <br />
                    <small
                      v-if="
                        data.item.metadata[Number(autoEvaluation.class) - 1]
                          .is_required
                      "
                      class="text-danger"
                      >Ce champ est requis</small
                    > -->
                    </div>
                  </template>
                  <template #cell(responses)="dataRes">
                    <div>
                      <div
                        class="d-flex"
                        :class="{
                          'error-border':
                            dataRes.item.selected_id === null &&
                            canFinishProcess === false,
                        }"
                      >
                        <span
                          v-for="(radItem, radIndex) in dataRes.item.responses"
                          :key="radIndex"
                          class="d-flex align-items-center"
                          style="gap: 8px; margin-top: 4px"
                        >
                          <input
                            type="radio"
                            :id="
                              sanitizeAndSlugifyString(
                                radItem.id + dataRes.item.question
                              )
                            "
                            :value="dataRes.item.selected_id"
                            :checked="dataRes.item.selected_id === radItem.id"
                            style="accent-color: #0e6258"
                            :name="
                              sanitizeAndSlugifyString(dataRes.item.question)
                            "
                            @change="
                              hasSelectedAnAnswer(secItem.id, dataRes.item.id)
                            "
                            @input="
                              () => {
                                if (dataRes.item.selected_id === null) {
                                  secItem.total_points.total_responded_questions += 1
                                }
                                dataRes.item.selected_id = Number(radItem.id)
                              }
                            "
                          />
                          <!-- 🥒🥒🥒🥒🥒🥒 -->
                          <!-- :value="radItem.id" -->
                          <!-- :checked="dataRes.item.selected_id === radItem.id" -->
                          <label
                            class="m-0"
                            :for="
                              sanitizeAndSlugifyString(
                                radItem.id + dataRes.item.question
                              )
                            "
                            >{{ radItem.label }}</label
                          >
                          <br />
                        </span>
                      </div>
                    </div>
                  </template>
                </b-table>
                <!-- <div
                class="d-flex justify-content-end"
                @click="continueWithNextCollapse(secItem.id)"
              >
                <b-button size="sm" variant="primary">Continuer</b-button>
              </div> -->
              </custom-app-collapse-item>
            </app-collapse>
          </b-tab>
        </div>
      </b-tabs>

      <div class="text-center">
        <div class="mt-2 d-flex justify-content-center" style="gap: 1em">
          <b-button
            v-if="tabIndex === 0 && autoEvaluation.data.chapters.length > 1"
            variant="gradient-primary"
            size="sm"
            :disabled="isSendingEvaluation || isPendingUpdate"
            @click="tabIndex++"
          >
            <!-- @clickz="handleVerification(tabIndex)" -->
            <!-- @cliczk="tabIndex++" -->
            <span class="text-nowrap"
              >Chapitre suivant

              <i
                class="las la-arrow-right la-lg d-inline-block"
                style="margin-left: 5px"
              />
            </span>
          </b-button>
          <b-button
            v-if="tabIndex > 0"
            variant="gradient-primary"
            size="sm"
            :disabled="isSendingEvaluation || isPendingUpdate"
            @click="tabIndex--"
          >
            <span class="text-nowrap">
              <i
                class="las la-arrow-left la-lg d-inline-block"
                style="margin-right: 5px"
              />
              Chapitre précédent
            </span>
          </b-button>
          <b-button
            v-if="tabIndex === autoEvaluation.data.chapters.length - 1"
            variant="gradient-primary"
            size="sm"
            :disabled="isSendingEvaluation || isPendingUpdate"
            @click="openConfirmationModal()"
          >
            <span class="text-nowrap"
              >Terminer

              <i
                class="las la-check la-lg d-inline-block"
                style="margin-left: 5px"
              />
            </span>
          </b-button>
          <b-button
            v-if="
              tabIndex != 0 &&
              tabIndex !== autoEvaluation.data.chapters.length - 1
            "
            variant="gradient-primary"
            size="sm"
            :disabled="isSendingEvaluation || isPendingUpdate"
            @click="tabIndex++"
          >
            <span class="text-nowrap"
              >Chapitre suivant

              <i
                class="las la-arrow-right la-lg d-inline-block"
                style="margin-left: 5px"
              />
            </span>
          </b-button>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-2">
        <b-button
          variant="gradient-primary"
          style="margin-right: 8px"
          size="sm"
          @click="modifySelectedIds(3, 1)"
        >
          1E3
        </b-button>
        <b-button
          variant="gradient-primary"
          style="margin-right: 8px"
          size="sm"
          @click="modifySelectedIds(7, 2)"
        >
          2E7
        </b-button>
        <b-button
          variant="gradient-primary"
          style="margin-right: 8px"
          size="sm"
          @click="modifySelectedIds(11, 3)"
        >
          3E11
        </b-button>
        <b-button
          variant="gradient-primary"
          style="margin-right: 8px"
          size="sm"
          @click="modifySelectedIds(13, 4)"
        >
          4E13
        </b-button>
        <b-button
          variant="gradient-primary"
          style="margin-right: 8px"
          size="sm"
          @click="modifySelectedIds(16, 5)"
        >
          5E16
        </b-button>
        <b-button
          variant="gradient-primary"
          style="margin-right: 8px"
          size="sm"
          @click="modifySelectedIds(16, 2)"
        >
          E
        </b-button>
      </div>
    </b-card>

    <!-- Control buttons-->
  </div>
</template>

<script>
import {
  BButtonGroup,
  BCard,
  BTab,
  BTabs,
  BTable,
  BCardSubTitle,
  BCardText,
  BBadge,
  BAlert,
  BModal,
  BProgress,
  BButton,
  BFormRating,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BSkeleton,
  BSpinner,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CustomAppCollapseItem from '@core/components/app-collapse/CustomAppCollapseItem.vue'
import ResultOutputAutoEval from '@/components/ResultOutputAutoEval.vue'
import evaluationsStoreModule from '@/store/evaluations'
import etablissementsStoreModule from '@/store/etablissements'
import localstorageService from '@/services/localstorage/localstorage.service'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    ResultOutputAutoEval,
    AppCollapse,
    AppCollapseItem,
    CustomAppCollapseItem,
    BButtonGroup,
    BButton,
    BCard,
    BTab,
    BTabs,
    BTable,
    BCardSubTitle,
    BCardText,
    BBadge,
    BAlert,
    BModal,
    BProgress,
    BFormRating,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BSkeleton,
    BSpinner,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'evaluation', module: evaluationsStoreModule },
      { path: 'etablissements', module: etablissementsStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },
  data: () => ({
    showTutoriel: true,
    tabIndex: 0,
    // ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
    options_type_secteur_hebergement: [],
    progressPercentage: 0,
    progressData: {
      total: 0,
      answered: 0,
    },
    isLoading: false,
    selected: '',
    collapseItems: [],
    canFinishProcess: null,
    fieldsChap: [
      {
        key: 'chap_rub',
        label: 'Chapitres et rubriques',
      },
      {
        key: 'chap_percent',
        label: '% Pondéré',
      },
    ],
    itemsChap: [],
    fieldsSections: [
      {
        key: 'label',
        label: 'Label',
      },
      {
        key: 'percentage',
        label: '%',
      },
    ],
    tableColumnsQuestion: [
      // {
      //   key: 'id',
      //   label: 'No',
      //   sortable: true,
      // },
      {
        key: 'question',
        label: 'Critères',
      },
      {
        key: 'responses',
        label: 'Réponses',
      },
    ],
    tableColumnsQuestion: [
      {
        key: 'question',
        label: 'Critères',
      },
      {
        key: 'responses',
        label: 'Réponses',
      },
    ],

    // tableData: [
    // ],
    isSendingEvaluation: false,
    isProcessingEvaluation: false,
    isFetchingAutoEvaluation: false,
    isPendingUpdate: false,
    autoEvaluation: null,
    readyForSaves: false,
    evalObj: {
      etabType: '',
      rankingSelected: '',
    },
    evaluationResult: null,
    starEligibility: 1,
  }),
  computed: {
    totalOfQuestions() {
      if (this.autoEvaluation && this.autoEvaluation.data) {
        let sum = 0
        this.autoEvaluation.data.chapters.forEach((chapter) => {
          chapter.sections.forEach((sec) => {
            sum += sec.questions.length
          })
        })
        return sum
      }
      return 0
    },
    selectedQuestions() {
      if (this.autoEvaluation && this.autoEvaluation.data) {
        const numberOfNonNullSelectedIds =
          this.autoEvaluation.data.chapters.reduce(
            (count, section) =>
              count +
              section.sections.reduce(
                (sectionCount, question) =>
                  sectionCount +
                  question.questions.reduce(
                    (questionCount, q) =>
                      questionCount + (q.selected_id !== null ? 1 : 0),
                    0
                  ),
                0
              ),
            0
          )
        return numberOfNonNullSelectedIds
      }
      return 0
    },
    progressCalculation() {
      if (this.autoEvaluation && this.autoEvaluation.data) {
        const progressCalc =
          (this.selectedQuestions / this.totalOfQuestions) * 100
        return progressCalc
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        // modal-informations
        this.isProcessingEvaluation = true
        // this.getListTypeHebergement();
        this.loadAutoEvaluation()
        this.isProcessingEvaluation = false
      },
    },
  },
  mounted() {
    if (localstorageService.getAutoEvalTut()) {
      if (!JSON.parse(localstorageService.getAutoEvalTut())) {
        this.showTutoriel = false
      } else {
        this.showTutoriel = true
      }
    } else {
      localstorageService.setAutoEvalTut(true)
    }
  },
  methods: {
    ...mapActions('evaluation', {
      action_getEvaluation: 'getEvaluation',
      action_updateEvaluation: 'updateEvaluation',
    }),
    ...mapActions('etablissements', {
      action_searchEtablissement: 'searchEtablissement',
      action_getListTypesEtablissement: 'getListTypesEtablissement',
      action_searchTypeEtablissement: 'searchTypeEtablissement',
    }),
    // Chargement des types detablissements
    getListTypeHebergement() {
      this.action_searchTypeEtablissement()
        .then((res) => {
          this.options_type_secteur_hebergement = res.data.data || []
        })
        .catch((err) => {})
    },
    loadAutoEvaluation() {
      // this.isProcessingEvaluation = true;
      this.action_getEvaluation(this.$route.params.id)
        .then((response) => {
          const res = response.data
          if (response.data) {
            if (response.data.status === 'Terminated') {
              this.$router.push({
                name: 'auto-evaluation-list',
              })
              return
            }
          }
          this.isProcessingEvaluation = false

          // console.log('before response.data', response.data);
          if (res.progression === null) {
            res.progression = {
              progressPercentage: 0,
              progressData: {
                total: 0,
                answered: 0,
              },
            }
          }
          // console.log('after response.data', response.data);
          // console.log('res', res);

          // let sortedChapters = res.data.chapters.sort((a, b) => a.n - b.n);
          // res.data.chapters = sortedChapters;

          this.autoEvaluation = res
          // this.readyForSaves = true;
          console.log('autoEvaluation 🔥: ', this.autoEvaluation)
          // this.isProcessingEvaluation = false;
        })
        .catch((error) => {
          console.log(error)
          this.isProcessingEvaluation = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title:
                "Une erreur est survenue lors du chargement de l'auto évaluation",
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: '',
            },
          })
        })
    },
    update_evaluation(payload) {
      const { statut } = payload
      if (statut === 'Terminated') {
        this.isSendingEvaluation = true
      }
      // this.calculatePercents();
      if (this.isPendingUpdate === true) return
      const IdToUpdate = this.$route.params.id
      const params = {
        data: this.autoEvaluation.data,
        status: statut,
        progression: {
          progressPercentage:
            this.autoEvaluation.progression.progressPercentage,
          progressData: {
            total: this.autoEvaluation.progression.progressData.total,
            answered: this.autoEvaluation.progression.progressData.answered,
          },
        },
      }
      console.log(params)
      // return;
      this.isPendingUpdate = true
      this.action_updateEvaluation({ id: IdToUpdate, params })
        .then(async (response) => {
          if (response.status === 202) {
            // console.log('DATA FINISH 🚀🚀🚀🚀', response.data);

            // this.autoEvaluation = response.data;
            if (statut === 'Terminated') {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title:
                    "Merci d'avoir rempli le formulaire. Votre auto-évaluation à bien été enregistrer. Vos résultats vous seront transmis sous réserve de validation.",
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              const remappedChapter = this.remapChapter(
                response.data.data.chapters
              )
              const res = response.data
              res.data.chapters = remappedChapter
              // console.log(res);
              // console.log(remappedChapter);
              this.evaluationResult = res

              this.isSendingEvaluation = false
              // this.finished_autoeval = true;
            }
            this.isPendingUpdate = false
            // this.$toast({
            //   component: ToastificationContent,
            //   position: 'top-right',
            //   props: {
            //     title: 'Enregistrement reussie',
            //     icon: 'CheckIcon',
            //     variant: 'success',
            //   },
            // });
          }
        })
        .catch((err) => {
          if (statut === 'Terminated') {
            this.isSendingEvaluation = true
          }
          this.isPendingUpdate = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    sendEvaluation(props) {
      this.evaluationResult = null
      this.$refs['modal-end-evaluation'].show()
      this.isSendingEvaluation = true
      this.update_evaluation({ statut: 'Terminated' })

      this.isSendingEvaluation = false
    },
    deepCopy(obj) {
      // Convert the object to a JSON string
      const jsonString = JSON.stringify(obj)

      // Parse the JSON string back to an object
      const copiedObj = JSON.parse(jsonString)

      return copiedObj
    },
    sanitizeAndSlugifyString(inputString) {
      // Remove special characters, punctuation, and spaces
      const sanitizedString = inputString.replace(/[^\w\s-]/g, '').trim()

      // Convert spaces to dashes and make lowercase
      const slugifiedString = sanitizedString.replace(/\s+/g, '-').toLowerCase()

      return slugifiedString
    },
    hasSelectedAnAnswer(sec, ques) {
      this.$nextTick()
      this.filterNotInvolvedQuestions3()
      this.$nextTick()
      this.update_evaluation({ statut: 'Ongoing' })
      this.canFinishProcess = this.handleVerificationFinishSingle()
    },
    remapChapter(chapters) {
      const chaptersWithPercentage = chapters.map((chapter) => {
        const sectionsWithPercentage = chapter.sections.map((section) => {
          const totalQuestions = section.questions.length
          const selectedQuestions = section.questions.filter(
            (question) => question.selected_id !== null
          ).length
          const percentage = (selectedQuestions / totalQuestions) * 100
          return {
            ...section,
            percentage: percentage.toFixed(2), // Rounded to 2 decimal places
          }
        })
        return {
          ...chapter,
          sections: sectionsWithPercentage,
        }
      })
      return chaptersWithPercentage
    },
    step_required() {
      return new Promise(async (resolve, reject) => {
        const isFormCorrect = await this.$v.$validate()
        if (!this.$v.form.requiredAnswer.$error && this.requiredAnswer_ok)
          resolve(true)
        else {
          this.alert_validate_inputs()
        }
        reject()
      })
    },
    alert_validate_inputs() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Veuillez valider les champs requis.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
    closeModal() {
      this.$router.push({
        name: 'auto-evaluation-list',
      })
      this.$refs['modal-end-evaluation'].hide()
    },
    handleVerification(props) {
      console.log('TAB INDEX', props)
      const autoEval = this.autoEvaluation
      const filteredChapter = autoEval.data.chapters[Number(this.tabIndex)]
      console.log('filteredChapter', filteredChapter)
      let canPass = true
      filteredChapter.sections.forEach((section) => {
        const questionsF = section.questions.filter((question) => {
          // const meta = question.metadata.find(
          //   (meta) => Number(meta.rank) === Number(autoEval.class)
          // )
          // console.log('meta', meta);
          // if (meta.is_required && question.selected_id === null) {
          //   return true
          // } else return false
          // 🟢 26/Fevrier/24 ***************************
          if (question.selected_id === null) {
            return true
          }
          return false
        })
        // const questionsF = section.questions.filter(
        //   (question) =>
        //     question.metadata[Number(autoEval.class) - 1].is_required &&
        //     question.selected_id === null
        // );
        // console.log('sec', section.label);
        if (questionsF.length > 0) {
          canPass = false
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title:
          //       'Certains critères nécessitant une réponse n’ont pas été remplis. Veuillez fournir une réponse pour continuer !',
          //     icon: 'AlertTriangleIcon',
          //     variant: 'danger',
          //   },
          // });
          // return false;
        }
        // console.log('canPass', canPass);
      })
      if (canPass) {
        console.log(this.tabIndex)
        this.tabIndex += 1
        console.log(this.tabIndex)
        // return true;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              'Vous avez oubliez de répondre à une ou plusieurs questions de ce chapitre!',
            // 'Certains critères nécessitant une réponse n’ont pas été remplis. Veuillez fournir une réponse pour continuer !',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    handleVerificationFinish() {
      const autoEval = this.autoEvaluation
      const canPassArray = []
      autoEval.data.chapters.forEach((chapter) => {
        chapter.sections.forEach((section) => {
          section.questions.forEach((question) => {
            if (question.selected_id === null) {
              canPassArray.push(false)
            } else {
              canPassArray.push(true)
            }
          })
        })
      })
      // console.table(canPassArray)

      if (canPassArray.every((el) => el === true)) {
        // console.log(this.tabIndex)
        // this.tabIndex = this.tabIndex + 1
        // console.log(this.tabIndex)
        return true
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title:
            'Vous avez oubliez de répondre à une ou plusieurs questions de ce chapitre!',
          // 'Certains critères nécessitant une réponse n’ont pas été remplis. Veuillez fournir une réponse pour continuer !',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return false
    },
    handleVerificationFinishSingle() {
      const autoEval = this.autoEvaluation
      const canPassArray = []
      autoEval.data.chapters.forEach((chapter) => {
        chapter.sections.forEach((section) => {
          section.questions.forEach((question) => {
            if (question.selected_id === null) {
              canPassArray.push(false)
            } else {
              canPassArray.push(true)
            }
          })
        })
      })
      // console.table(canPassArray)

      if (canPassArray.every((el) => el === true)) {
        // console.log(this.tabIndex)
        // this.tabIndex = this.tabIndex + 1
        // console.log(this.tabIndex)
        return true
      }
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title:
      //       'Vous avez oubliez de répondre à une ou plusieurs questions de ce chapitre!',
      //     // 'Certains critères nécessitant une réponse n’ont pas été remplis. Veuillez fournir une réponse pour continuer !',
      //     icon: 'AlertTriangleIcon',
      //     variant: 'danger',
      //   },
      // })
      return false
    },
    continueWithNextCollapse(sectionId) {
      const autoEvaluationData = this.autoEvaluation
      const chapter = autoEvaluationData.data.chapters.find((chap) =>
        chap.sections.some((sec) => sec.id === sectionId)
      )

      if (chapter) {
        const sectionIndex = chapter.sections.findIndex(
          (sec) => sec.id === sectionId
        )

        // Check if the sectionId is found and there's a next section
        if (sectionIndex !== -1 && sectionIndex < chapter.sections.length - 1) {
          // Set current section's isOpen to false
          chapter.sections[sectionIndex].isOpen = false

          // Set the next section's isOpen to true
          chapter.sections[sectionIndex + 1].isOpen = true
        }
      }

      this.autoEvaluation = autoEvaluationData
      this.$nextTick()
      console.log(
        '🥒🥒🥒🥒 updated autoeval',
        autoEvaluationData.data.chapters[0].sections
      )
    },
    modifySelectedIds(numberOfChapter, star) {
      // let jsonData = this.deepCopy(this.autoEvaluation);
      // jsonData.data.chapters.forEach((chapter) => {
      //   if (chapter.n <= numberOfChapter) {
      //     chapter.sections.forEach((section) => {
      //       section.questions.forEach((question) => {
      //         question.selected_id = 1;
      //       });
      //     });
      //   }
      // });
      // this.autoEvaluation = jsonData;
      // this.setEligible(starWanted);

      // 💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠
      // 💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠
      // 💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠
      // 💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠💠
      const jsonData = this.deepCopy(this.autoEvaluation)

      jsonData.data.chapters.forEach((chapter) => {
        console.log(
          'chapter.n <= numberOfChapter',
          chapter.n <= numberOfChapter
        )
        if (chapter.n <= numberOfChapter) {
          chapter.sections.forEach((section) => {
            section.questions.forEach((question) => {
              // const filteredMetadata = question.metadata.filter(
              //   (meta) => Number(meta.rank) === Number(star)
              // )

              // // console.log('filteredMetadata', filteredMetadata);
              // if (filteredMetadata.length > 0) {
              //   const selectedMetadata = filteredMetadata.find(
              //     (meta) => meta.type === 'MAJOR' || meta.type === 'MINOR'
              //   )
              //   // console.log('selectedMetadata', selectedMetadata);

              //   if (selectedMetadata) {
              //     question.selected_id = 1
              //   }
              // }
              question.selected_id = 1
            })
          })
        }
      })
      console.log('this.autoEvaluation====jsonData', jsonData)
      this.autoEvaluation = jsonData
      // this.setEligible(star);
    },
    setEligible(star) {
      this.starEligibility = star
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return [
          'small-margin',
          'rounded',
          'bg-primary',
          'text-light',
          'noclickable-a',
        ]
      }
      return [
        'small-margin',
        'rounded',
        'bg-light-custom',
        'text-secondary',
        'noclickable-a',
      ]
    },
    openConfirmationModal() {
      this.canFinishProcess = this.handleVerificationFinish()
      console.log('jskds', this.canFinishProcess)
      if (this.canFinishProcess) {
        this.$refs['modal-confirmation-process'].show()
      }
    },
    closeConfirmationModal() {
      this.$refs['modal-confirmation-process'].hide()
    },
    launchProcess() {
      this.$refs['modal-confirmation-process'].hide()
      this.sendEvaluation()
    },
    filterNotInvolvedQuestions() {
      if (!this.autoEvaluation || !this.autoEvaluation.data) {
        return []
      }

      // Deep clone the data to avoid mutating the original structure
      const filteredChapters = JSON.parse(
        JSON.stringify(this.autoEvaluation.data.chapters)
      )

      filteredChapters.forEach((chapter) => {
        chapter.sections.forEach((section) => {
          section.questions = section.questions.filter((question) => {
            // Check if the question has a parent and if the parent's question_id
            // matches the id of another question at the same level
            if (question.parent && question.parent.question_id) {
              const parentQuestionId = question.parent.question_id
              const parentResponseId = question.parent.response_id

              const matchingQuestion = section.questions.find(
                (q) => q.id === parentQuestionId
              )

              // Check if the matching question has the same selected_id
              // return (
              //   !matchingQuestion ||
              //   matchingQuestion.selected_id === null ||
              //   matchingQuestion.selected_id === parentResponseId
              // );
              return (
                matchingQuestion &&
                matchingQuestion.selected_id === parentResponseId
              )
            }

            // If the question doesn't have a parent, or the conditions are not met, include it
            return true
          })
        })
      })
      console.log('🔹🔹🔹', filteredChapters)

      this.autoEvaluation.data.chapters = filteredChapters
    },
    filterNotInvolvedQuestions2() {
      if (!this.autoEvaluation || !this.autoEvaluation.data) {
        return []
      }

      // Deep clone the data to avoid mutating the original structure
      const filteredChapters = JSON.parse(
        JSON.stringify(this.autoEvaluation.data.chapters)
      )

      filteredChapters.forEach((chapter) => {
        chapter.sections.forEach((section) => {
          section.questions = section.questions.filter((question) => {
            // Check if the question has a parent and if the parent's question_id
            // matches the id of another question at the same level
            if (question.parent && question.parent.question_id) {
              const parentQuestionId = question.parent.question_id
              const parentResponseId = question.parent.response_id

              const matchingQuestion = section.questions.find(
                (q) => q.id === parentQuestionId
              )

              // Check if the matching question has the same selected_id
              // return (
              //   !matchingQuestion ||
              //   matchingQuestion.selected_id === null ||
              //   matchingQuestion.selected_id === parentResponseId
              // );
              return (
                !matchingQuestion &&
                (!matchingQuestion.selected_id === parentResponseId ||
                  !matchingQuestion.selected_id === null)
              )
            }

            // If the question doesn't have a parent, or the conditions are not met, include it
            return true
          })
        })
      })
      console.log('🔹🔹🔹', filteredChapters)

      this.autoEvaluation.data.chapters = filteredChapters
    },
    filterNotInvolvedQuestions3() {
      if (!this.autoEvaluation || !this.autoEvaluation.data) {
        return []
      }

      // Deep clone the data to avoid mutating the original structure
      const filteredChapters = JSON.parse(
        JSON.stringify(this.autoEvaluation.data.chapters)
      )

      filteredChapters.forEach((chapter) => {
        chapter.sections.forEach((section) => {
          section.questions = section.questions.map((question) => {
            // Check if the question has a parent and if the parent's question_id
            // matches the id of another question at the same level
            if (question.parent && question.parent.question_id) {
              const parentQuestionId = question.parent.question_id
              const parentResponseId = question.parent.response_id

              const matchingQuestion = section.questions.find(
                (q) => q.id === parentQuestionId
              )

              if (
                matchingQuestion &&
                matchingQuestion.selected_id !== parentResponseId
              ) {
                return { ...question, show: true }
              }

              // matchingQuestion &&
              // (matchingQuestion.selected_id === parentResponseId ||
              //   matchingQuestion.selected_id === null)
              // Check if the matching question has the same selected_id
              if (
                matchingQuestion &&
                matchingQuestion.selected_id === parentResponseId
              ) {
                // Modify the show attribute to false
                return { ...question, show: false }
              }
            }

            // If the question doesn't have a parent, or the conditions are not met, include it
            return question
          })
        })
      })
      console.log('🔹🔹🔹', filteredChapters)

      // this.autoEvaluation.data.chapters = filteredChapters;
      this.autoEvaluation.data.chapters = filteredChapters
    },
    refiltering(chapters) {
      console.log('▶▶▶')
      const chaptersRefiltered = chapters
      chaptersRefiltered.forEach((chapter) => {
        chapter.sections.forEach((section) => {
          section.questions = section.questions.filter(
            (question) => question.show
          )
        })
      })
      return chaptersRefiltered
    },
    handleTutorielMessage() {
      localstorageService.setAutoEvalTut(false)
      this.showTutoriel = false
    },
  },
}
</script>

<style>
.autoeval-process .nav-tabs {
  /* padding: 1em 2em 1.5em 2em !important; */
  /* background-color: crimson !important; */
  /* user-select: none;
  pointer-events: none;
  cursor: none; */
}
.hovering-infos-backdrop {
  position: fixed;
  inset: 0;
  z-index: 8000;
  background-color: #00000069;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 4em 2em;
}
.hovering-card {
  max-width: 1100px;
  /* max-width: 500px; */
}
.error-border {
  width: 90%;
  border: 2px solid red;
  border-radius: 2px;
  padding: 5px 10px;
}
</style>
