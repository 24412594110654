<template>
  <div>
    <b-table-simple hover fixed head-variant="dark" responsive small>
      <b-thead>
        <b-tr class="py-1 gr">
          <b-th>TOTALS</b-th>
          <b-th>{{ totals.major.toFixed(2) + ' %' }}</b-th>
          <b-th>{{ totals.minor.toFixed(2) + ' %' }}</b-th>
        </b-tr>
        <b-tr>
          <b-th>Chapitre</b-th>
          <b-th>Majeur</b-th>
          <b-th>Mineur</b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-for="(chap, chapIndex) in results.data" :key="chapIndex">
        <b-tr>
          <b-th>{{ chap.label }}</b-th>
          <b-th>{{ chap.percents.major.toFixed(2) + ' %' }}</b-th>
          <b-th>{{ chap.percents.minor.toFixed(2) + ' %' }}</b-th>
        </b-tr>
        <b-tr
          v-for="(section, sectionIndex) in chap.sections"
          :key="sectionIndex"
        >
          <b-td>{{ section.label }}</b-td>
          <b-td rowspan="1">{{
            section.percents.major.toFixed(2) + ' %'
          }}</b-td>
          <b-td rowspan="1">{{
            section.percents.minor.toFixed(2) + ' %'
          }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- <div
      v-if="(currentTableIndex + 1) % 3 === 0"
      class="html2pdf__page-break"
    ></div> -->
  </div>
</template>

<script>
import { BTableSimple, BThead, BTr, BTh, BTd, BTbody } from 'bootstrap-vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
  },
  // data: () => ({
  //   currentTableIndex: -1,
  // }),

  props: {
    classementNumber: {
      type: String,
      required: true,
      default: '1',
    },
    results: {
      type: Object,
      required: true,
    },
    totals: {
      type: Object,
      required: true,
    },
  },

  // watch: {
  //   formattedResults: {
  //     handler() {
  //       this.currentTableIndex = -1;
  //     },
  //     deep: true,
  //   },
  // },

  computed: {
    // formattedResults() {
    //   let res = this.results;
    //   const updatedData = {};
    //   for (const key in res) {
    //     if (res.hasOwnProperty(key)) {
    //       const newKey = key.replace(' Etoile', '').replace('s', '');
    //       updatedData[newKey] = res[key];
    //     }
    //   }
    //   return updatedData;
    // },
  },
}
</script>
<style scoped>
.bg {
  background-color: #f3f2f7;
}
.gr {
  background-color: rgba(128, 128, 128, 0.527) !important;
  color: black !important;
  font-weight: bold !important;
  padding: 10px 0px !important;
}
</style>
